import React from "react";


const CarouselImage=()=>{

    return (
        <div>

        </div>
    )
}

export default CarouselImage