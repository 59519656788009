import React, {Component} from 'react'
import Header from '../../common/Header/Header'
 import Footer from '../../common/Footer/Footer'
import DisplayContnent from '../../common/Displaycontent/DisplayContnent'
import Contact from '../contact/Contact'
import Homepage from '../homepage/homepage'
 
class DefaultLayout extends Component {
  render(){
      return (
            <div className='Defaultlayout'> 
          
                  <Header/> 
                 
                  <Footer/> 
            </div>   
          
      )
  }
}

 

export default DefaultLayout