import React from "react";


const DisplayContnent = (props) => {


    return (
        <div className="container-xxl py-5 Hi">
            <h3> {props.title}</h3>

            {props.content ?
                <p>{props.content}</p>
                : <></>}

        </div>
    )
}

export default DisplayContnent

