import React, { useEffect, useState } from 'react'
 import Herobanner from '../../common/Herobanner/Herobanner'
import Alumni from '../../common/Alumni/Alumni'
import DisplayContnent from '../../common/Displaycontent/DisplayContnent'
import Services from '../../common/Services/Services'
import { getAlumni} from '../../redux/services/apicall'
import { getOverviews} from '../../redux/services/apicall'

import { useDispatch, useSelector } from 'react-redux'

import {fetchAlumniItemsFailure,fetchAlumniItemsSuccess, fetchAlumniItemRequest} from '../../redux/action/fetchaction'
import {fetchOverviewItemsFailure,fetchOverviewItemsSuccess, fetchOverviewItemRequest} from '../../redux/action/fetchaction'


import {fetchFacilityItemsFailure, fetchFacilityItemRequest,fetchFacilityItemsSuccess } from '../../redux/action/fetchaction'
  import {getfacilities} from '../../redux/services/apicall'

      
  
const Homepage = () => {

    const dispatch = useDispatch()
    const { alumnicontent } = useSelector((state) => state.alumnireducer)
    const { overviewcontent } = useSelector((state) => state.overviewreducer) 
    const { facilitycontent } = useSelector((state) => state.facilityreducer);


    useEffect(() => {
        const fetchItems = async (data) => {
          dispatch(fetchFacilityItemRequest())
          try {
            //const data = await fetchoveriewFromApi()
            const response = await getfacilities()
            dispatch(fetchFacilityItemsSuccess(response.data))
            console.log(response.data)
          } catch (error) {
            dispatch(fetchFacilityItemsFailure(error.message))
          }
        }
    
        fetchItems()
      }, [dispatch])

    useEffect(() => {
        const fetchItems = async (data) => {
          dispatch(fetchOverviewItemRequest())
          try {
            //const data = await fetchoveriewFromApi()
            const data = await getOverviews()
            dispatch(fetchOverviewItemsSuccess(data))
            console.log(data)
          } catch (error) {
            dispatch(fetchOverviewItemsFailure(error.message))
          }
        }
    
        fetchItems()
      }, [dispatch])

    useEffect(() => {
        const fetchItems = async (data) => {
          dispatch(fetchAlumniItemRequest())
          try {
            const data = await getAlumni()
            dispatch(fetchAlumniItemsSuccess(data))
            console.log(data)
          } catch (error) {
            dispatch(fetchAlumniItemsFailure(error.message))
          }
        }
    
        fetchItems()
      }, [dispatch])

    return (
        <div> 
              <Herobanner/> 
              {overviewcontent && overviewcontent.length > 0 ?
              <DisplayContnent title={overviewcontent[0].title} content={overviewcontent[0].content} />
          : <></>
        }

<div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4">
                  
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item text-center pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
                                    <h5 className="mb-3">Hostel </h5>
                                    <p> Hostel accommodation is provided in the school, though many have not heard so....</p>
                                    <a class="btn btn-primary py-1 px-5 mt-1" href="/Facilities">Read More</a>
                                </div>
                            </div>
                        </div>
                 
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item text-center pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                                    <h5 className="mb-3">Computer Lab</h5>
                                    <p>In the era of Information & Technology, computer skills are essential.Our students become computer savvy ...</p>
                                    <a class="btn btn-primary py-1 px-5 mt-1" href="/Facilities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item text-center pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-home text-primary mb-4"></i>
                                    <h5 className="mb-3">Swimming Pool</h5>
                                    <p>The Swimming Pool was inaugurated on the 8th November 2014 at the hands of Mr. Frank Roland Freese, ...</p>
                                    <a class="btn btn-primary py-1 px-5 mt-1" href="/Facilities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item text-center pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                                    <h5 className="mb-3">Canteen</h5>
                                    <p>Canteen facility is provided in the school. This Canteen is run under the personal supervision of the management...</p>
                                    <a class="btn btn-primary py-1 px-5 mt-1" href="/Facilities">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Instructors</h6>
                        <h1 className="mb-5">Alumini</h1>
                    </div>
            {alumnicontent ? 
              <Alumni  content={alumnicontent}></Alumni>
              :<></>}
              
          
         </div>
    )
}
export default Homepage