// actions.js

import {FITCH_SLIIDER_ITEM_REQUEST,FITCH_SLIIDER_ITEM_SUCCESS,FITCH_SLIIDER_ITEM_FAILURE,UPLOAD_IMAGE,UPDATE_OVERVIEW_CONTENT, DELETE_OVERVIEW_CONTENT,} from '../constants/constants'
import {FITCH_OVERVIEW_ITEM_REQUEST,FITCH_OVERVIEW_ITEM_SUCCESS,FITCH_OVERVIEW_ITEM_FAILURE,ADD_OVERVIEW_CONTENT,} from '../constants/constants'
import {ADD_ALUMNI_CONTENT,FITCH_ALUMNI_ITEM_FAILURE,FITCH_ALUMNI_ITEM_REQUEST,FITCH_ALUMNI_ITEM_SUCCESS,UPDATE_ALUMNI_CONTENT,DELETE_ALUMNI_CONTENT } from '../constants/constants'
import {ADD_ABOUTUS_CONTENT,FITCH_ABOUTUS_ITEM_FAILURE,FITCH_ABOUTUS_ITEM_REQUEST,FITCH_ABOUTUS_ITEM_SUCCESS,DELETE_ABOUTUS_CONTENT,UPDATE_ABOUTUS_CONTENT } from '../constants/constants'
import { DELETE_ACHIVEMENT_CONTENT,UPDATE_ACHIVEMENT_CONTENT,ADD_ACHIVEMENT_CONTENT,FITCH_ACHIVEMENT_ITEM_FAILURE,FITCH_ACHIVEMENT_ITEM_SUCCESS,FITCH_ACHIVEMENT_ITEM_REQUEST } from '../constants/constants'
import { DELETE_FACILITY_CONTENT,UPDATE_FACILITY_CONTENT,ADD_FACILITY_CONTENT,FITCH_FACILITY_ITEM_FAILURE,FITCH_FACILITY_ITEM_SUCCESS,FITCH_FACILITY_ITEM_REQUEST } from '../constants/constants'
import { DELETE_GALLERY_CONTENT,UPDATE_GALLERY_CONTENT,ADD_GALLERY_CONTENT,FITCH_GALLERY_ITEM_FAILURE,FITCH_GALLERY_ITEM_SUCCESS,FITCH_GALLERY_ITEM_REQUEST } from '../constants/constants'
import { DELETE_VIDEO_CONTENT,UPDATE_VIDEO_CONTENT,ADD_VIDEO_CONTENT,FITCH_VIDEO_ITEM_FAILURE,FITCH_VIDEO_ITEM_SUCCESS,FITCH_VIDEO_ITEM_REQUEST } from '../constants/constants'

export const fetchSlidderItemRequest = () => ({
  type: FITCH_SLIIDER_ITEM_REQUEST,
})

export const fetchSlidderItemsSuccess = (items) => ({
  type: FITCH_SLIIDER_ITEM_SUCCESS,
  payload: items,
})

export const fetchSlidderItemsFailure = (error) => ({
  type: FITCH_SLIIDER_ITEM_FAILURE,
  payload: error,
})

//Upload Image

export const UploadImage = (uploadimage) => {
  return {
    type: UPLOAD_IMAGE,
    payload: uploadimage,
  }
}

// Overview action

export const fetchOverviewItemRequest = () => ({
  type: FITCH_OVERVIEW_ITEM_REQUEST,
})

export const fetchOverviewItemsSuccess = (items) => ({
  type: FITCH_OVERVIEW_ITEM_SUCCESS,
  payload: items,
})

export const fetchOverviewItemsFailure = (error) => ({
  type: FITCH_OVERVIEW_ITEM_FAILURE,
  payload: error,
})

export const addoverview = (overview) => {
  return {
    type: ADD_OVERVIEW_CONTENT,
    payload: overview,
  }
}

export const updateOverview = (overview) => {
  return {
    type: UPDATE_OVERVIEW_CONTENT,
    payload: overview,
  }
}


export const deleteOverview = (overview) => {
  return {
    type: DELETE_OVERVIEW_CONTENT,
    payload: overview,
  }
}

//------Alumni action

 
export const fetchAlumniItemRequest = () => ({
  type: FITCH_ALUMNI_ITEM_REQUEST,
})

export const fetchAlumniItemsSuccess = (items) => ({
  type: FITCH_ALUMNI_ITEM_SUCCESS,
  payload: items,
})

export const fetchAlumniItemsFailure = (error) => ({
  type: FITCH_ALUMNI_ITEM_FAILURE,
  payload: error,
})

export const addAlumini = (alumni) => {
  return {
    type: ADD_ALUMNI_CONTENT,
    payload: alumni,
  }
}

export const updateAlumini = (alumni) => {
  return {
    type: UPDATE_ALUMNI_CONTENT,
    payload: alumni,
  }
}


export const deleteAlumini = (alumni) => {
  return {
    type: DELETE_ALUMNI_CONTENT,
    payload: alumni,
  }
}


//--Aboutus action

export const fetchAboutusItemRequest = () => ({
   type: FITCH_ABOUTUS_ITEM_REQUEST,
 })
 
 export const fetchAboutusItemsSuccess = (items) => ({
   type: FITCH_ABOUTUS_ITEM_SUCCESS,
   payload: items,
 })
 
 export const fetchAboutusItemsFailure = (error) => ({
   type: FITCH_ABOUTUS_ITEM_FAILURE,
   payload: error,
 })
 
 export const addAboutus = (about) => {
   return {
     type: ADD_ABOUTUS_CONTENT,
     payload: about,
   }
 }
 
 export const updateAboutus = (about) => {
   return {
     type: UPDATE_ABOUTUS_CONTENT,
     payload: about,
   }
 }
 
 
 export const deleteAboutus = (about) => {
   return {
     type: DELETE_ABOUTUS_CONTENT,
     payload: about,
   }
 }
 

 //---Achivement action

 export const fetchAchivmentItemRequest = () => ({
   type: FITCH_ACHIVEMENT_ITEM_REQUEST,
 })
 
 export const fetchAchivmentItemsSuccess = (items) => ({
   type: FITCH_ACHIVEMENT_ITEM_SUCCESS,
   payload: items,
 })
 
 export const fetchAchivmentItemsFailure = (error) => ({
   type: FITCH_ACHIVEMENT_ITEM_FAILURE,
   payload: error,
 })
 
 export const addAchivment = (achivement) => {
   return {
     type: ADD_ACHIVEMENT_CONTENT,
     payload: achivement,
   }
 }
 
 export const updateAchivment = (achivement) => {
   return {
     type: UPDATE_ACHIVEMENT_CONTENT,
     payload: achivement,
   }
 }
 
 
 export const deleteAchivment = (achivement) => {
   return {
     type: DELETE_ACHIVEMENT_CONTENT,
     payload: achivement,
   }
 }

 //-----Facility


 export const fetchFacilityItemRequest = () => ({
  type: FITCH_FACILITY_ITEM_REQUEST,
})

export const fetchFacilityItemsSuccess = (items) => ({
  type: FITCH_FACILITY_ITEM_SUCCESS,
  payload: items,
})

export const fetchFacilityItemsFailure = (error) => ({
  type: FITCH_FACILITY_ITEM_FAILURE,
  payload: error,
})

export const addFacility = (facility) => {
  return {
    type: ADD_FACILITY_CONTENT,
    payload: facility,
  }
}

export const updateFacility = (facility) => {
  return {
    type: UPDATE_FACILITY_CONTENT,
    payload: facility,
  }
}


export const deleteFacility = (facility) => {
  return {
    type: DELETE_FACILITY_CONTENT,
    payload: facility,
  }
}

//-----Gallery


export const fetchGalleryItemRequest = () => ({
  type: FITCH_GALLERY_ITEM_REQUEST,
})

export const fetchGalleryItemsSuccess = (items) => ({
  type: FITCH_GALLERY_ITEM_SUCCESS,
  payload: items,
})

export const fetchGalleryItemsFailure = (error) => ({
  type: FITCH_GALLERY_ITEM_FAILURE,
  payload: error,
})

export const addGallery = (gallery) => {
  return {
    type: ADD_GALLERY_CONTENT,
    payload: gallery,
  }
}

export const updateGallery = (gallery) => {
  return {
    type: UPDATE_GALLERY_CONTENT,
    payload: gallery,
  }
}


export const deleteGallery = (gallery) => {
  return {
    type: DELETE_GALLERY_CONTENT,
    payload: gallery,
  }
}


//-----Video


export const fetchVideoItemRequest = () => ({
  type: FITCH_VIDEO_ITEM_REQUEST,
})

export const fetchVideoItemsSuccess = (items) => ({
  type: FITCH_VIDEO_ITEM_SUCCESS,
  payload: items,
})

export const fetchVideoItemsFailure = (error) => ({
  type: FITCH_VIDEO_ITEM_FAILURE,
  payload: error,
})

export const addVideo = (video) => {
  return {
    type: ADD_VIDEO_CONTENT,
    payload: video,
  }
}

export const updateVideo = (video) => {
  return {
    type: UPDATE_VIDEO_CONTENT,
    payload: video,
  }
}


export const deleteVideo = (video) => {
  return {
    type: DELETE_VIDEO_CONTENT,
    payload: video,
  }
}

