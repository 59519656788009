import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage';
import carousel1  from '../../assets/images/carousel-1.jpg'
import carousel2  from '../../assets/images/carousel-2.jpg'

function Herobanner() {
  return (
    <Carousel slide={false}>
      <Carousel.Item>
        <CarouselImage text="First slide" />
        <Carousel.Caption>
            <img src={carousel1} />
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselImage text="Second slide" />
        <img src={carousel2} />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselImage text="Third slide" />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Herobanner;