import React, { useEffect, useState } from 'react'
import Services from '../../common/Services/Services'
import { fetchAboutusItemRequest, fetchAboutusItemsSuccess, fetchAboutusItemsFailure } from '../../redux/action/fetchaction'
import { fetchAchivmentItemRequest, fetchAchivmentItemsSuccess, fetchAchivmentItemsFailure } from '../../redux/action/fetchaction'

import { useDispatch, useSelector } from 'react-redux'
import { getAboutus } from '../../redux/services/apicall'
import { getachivement } from '../../redux/services/apicall'
import DisplayContnent from '../../common/Displaycontent/DisplayContnent'
import Alumni from '../../common/Alumni/Alumni'



const Aboutus = () => {
  const dispatch = useDispatch()
  const { aboutuscontent } = useSelector((state) => state.aboutusreducer)
  const { achivementcontent } = useSelector((state) => state.achivementreducer)


  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchAboutusItemRequest())
      try {
        //const data = await fetchoveriewFromApi()
        const data = await getAboutus()
        dispatch(fetchAboutusItemsSuccess(data))
        console.log(data)
      } catch (error) {
        dispatch(fetchAboutusItemsFailure(error.message))
      }
    }

    fetchItems()
  }, [dispatch])


  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchAchivmentItemRequest())
      try {
        //const data = await fetchoveriewFromApi()
        const response = await getachivement()
        dispatch(fetchAchivmentItemsSuccess(response.data))
        console.log(data)
      } catch (error) {
        dispatch(fetchAchivmentItemsFailure(error.message))
      }
    }

    fetchItems()
  }, [dispatch])


  return (
    <div>
      {aboutuscontent && aboutuscontent.length > 0 ?
      
        <DisplayContnent title={aboutuscontent[0].title} content={aboutuscontent[0].content} />
          : <></>
        }
       {achivementcontent ? 
              <Alumni  content={achivementcontent}></Alumni>
              :<></>}
    </div>
  )
}
export default Aboutus