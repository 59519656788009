import React, { useEffect, useState } from 'react'

const Alumni = (props) => {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                  
                    <div className="row g-4">

                        {props.content && props.content.map((item, index) => (
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="team-item bg-light">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src="img/team-1.jpg" alt="" />
                                    </div>
                                    <div className="position-relative d-flex justify-content-center margin-23"   >
                                        <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">{item.name}</h5>
                                        <h5 className="mb-0">{item.title}</h5> 
                                        <small>{item.content}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>





        </div>


    )
}
export default Alumni