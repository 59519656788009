import {
  FITCH_ALUMNI_ITEM_REQUEST,
  FITCH_ALUMNI_ITEM_SUCCESS,
  FITCH_ALUMNI_ITEM_FAILURE,
  ADD_ALUMNI_CONTENT,
  UPDATE_ALUMNI_CONTENT,
  DELETE_ALUMNI_CONTENT,
} from '../constants/constants'

const initialState = {
  alumnicontent: [],
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FITCH_ALUMNI_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FITCH_ALUMNI_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        alumnicontent: action.payload,
      }
    case FITCH_ALUMNI_ITEM_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      }
    case ADD_ALUMNI_CONTENT:
      return {
        ...state,
        loading: true,
        alumnicontent: [...state.alumnicontent, action.payload],
      }
    case UPDATE_ALUMNI_CONTENT:
      const contents = state.alumnicontent.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              content: action.payload.content,
              name: action.payload.name,
              image: action.payload.image,
            }
          : item,
      )

      return {
        ...state,
        loading: true,
        alumnicontent: contents,
      }

    case DELETE_ALUMNI_CONTENT:
      const removeContents = state.alumnicontent.filter((a) => a.id != action.payload.id)

      return {
        ...state,
        loading: true,
        alumnicontent: removeContents,
      }

    default:
      return state
  }
}

export default reducer
