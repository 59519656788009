import React, { useEffect, useState } from 'react'

const Contact = () => {
    return (

        
        <div className="container-xxl py-5">

            <div class="text-center wow fadeInUp titleanimation wow fadeIn" data-wow-delay="0.1s">
                <h6 class="section-title bg-white text-center text-primary px-3">Contact Us</h6>
                <h1 class="mb-5">Location</h1>
            </div>

            <div class="row d-flex mb-1 contact-info">
                <div class="col-md-12 d-flex ">
                    <div class="bg-light align-self-stretch box p-4 text-center boxframe">
                        <div class="row">
                            <div class="col-md-4">
                                <h3 class="mb-4">St Helena's School</h3>
                                <p>8, Susie Sorabji Rd, Agarkar Nagar
                                    Pune, Maharashtra 411001, India
                                    Tel.No: 020 - 2612 6796
                                    Email:sthelenas.school@gmail.com
                                    Website: www.sthelennas.in
                                </p>
                            </div>

                            <div class="col-md-8">
                                <iframe width="99%" height="300" frameborder="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1081778462176!2d73.87279600000001!3d18.52401300000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c05ba0f10381%3A0xc012744565ac7f73!2sSt+Helena's+School!5e0!3m2!1sen!2sin!4v1407958541154"></iframe>

                            </div>
                        </div>
                    </div>
                </div>
                



            </div>

        </div>
    )
}
export default Contact