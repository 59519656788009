import {
  FITCH_OVERVIEW_ITEM_REQUEST,
  FITCH_OVERVIEW_ITEM_SUCCESS,
  FITCH_OVERVIEW_ITEM_FAILURE,
  ADD_OVERVIEW_CONTENT,
  UPDATE_OVERVIEW_CONTENT,
  DELETE_OVERVIEW_CONTENT,
} from '../constants/constants'

const initialState = {
  overviewcontent: [],
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FITCH_OVERVIEW_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FITCH_OVERVIEW_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        overviewcontent: action.payload,
      }
    case FITCH_OVERVIEW_ITEM_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      }
    case ADD_OVERVIEW_CONTENT:
      return {
        ...state,
        loading: true,
        overviewcontent: [...state.overviewcontent, action.payload],
      }
    case UPDATE_OVERVIEW_CONTENT:
      const contents = state.overviewcontent.map((item) =>
        item.id === action.payload.id ? { ...item, content: action.payload.content } : item,
      )

      return {
        ...state,
        loading: true,
        overviewcontent: contents,
      }

    case DELETE_OVERVIEW_CONTENT:
      const removeContents = state.overviewcontent.filter((a) => a.id != action.payload.id)

      return {
        ...state,
        loading: true,
        overviewcontent: removeContents,
      }

    default:
      return state
  }
}

export default reducer
