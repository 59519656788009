import { FITCH_SLIIDER_ITEM_REQUEST,FITCH_SLIIDER_ITEM_SUCCESS,FITCH_SLIIDER_ITEM_FAILURE,UploadImage } from "../constants/constants"

const initialState = {
    slidderItems : [],
    loading: false,
    error:'' 
}

const reducer = (state = initialState,action) => {
    switch (action.type) {
        case FITCH_SLIIDER_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FITCH_SLIIDER_ITEM_SUCCESS:
            return {
                ...state,
                loading: true,
                slidderItems:action.payload, 
                
            };
        case FITCH_SLIIDER_ITEM_FAILURE:
            return {
                ...state,
                loading: true,
                error: action.payload
            };

        
         case UploadImage:
            return {
                    ...state,
                    loading:false,
                    slidderItems: action.payload
                    
            }
            
            default:
                return state;

    }
};

 export default reducer;