import {
    FITCH_GALLERY_ITEM_REQUEST,
    FITCH_GALLERY_ITEM_SUCCESS,
    FITCH_GALLERY_ITEM_FAILURE,
    ADD_GALLERY_CONTENT,
    UPDATE_GALLERY_CONTENT,
    DELETE_GALLERY_CONTENT,
  } from '../constants/constants'
  
  const initialState = {
    gallerycontent: [],
    loading: false,
    error: '',
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FITCH_GALLERY_ITEM_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case FITCH_GALLERY_ITEM_SUCCESS:
        return {
          ...state,
          loading: true,
          gallerycontent: action.payload,
        }
      case FITCH_GALLERY_ITEM_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload,
        }
      case ADD_GALLERY_CONTENT:
        return {
          ...state,
          loading: true,
          gallerycontent: [...state.gallerycontent, action.payload],
        }
      case UPDATE_GALLERY_CONTENT:
        const contents = state.gallerycontent.map((item) =>
          item.id === action.payload.id ? { ...item, image: action.payload.image,name: action.payload.name } : item,
        )
  
        return {
          ...state,
          loading: true,
          gallerycontent: contents,
        }
  
      case DELETE_GALLERY_CONTENT:
        const removeContents = state.gallerycontent.filter((a) => a.id != action.payload.id)
  
        return {
          ...state,
          loading: true,
          gallerycontent: removeContents,
        }
  
      default:
        return state
    }
  }
  
  export default reducer
  