import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import {fetchFacilityItemsFailure, fetchFacilityItemRequest,fetchFacilityItemsSuccess } from '../../redux/action/fetchaction'
  import {getfacilities} from '../../redux/services/apicall'
  
const Facilities = () => {

    const dispatch = useDispatch();
    const { facilitycontent } = useSelector((state) => state.facilityreducer);

    useEffect(() => {
        const fetchItems = async (data) => {
          dispatch(fetchFacilityItemRequest())
          try {
            //const data = await fetchoveriewFromApi()
            const response = await getfacilities()
            dispatch(fetchFacilityItemsSuccess(response.data))
            console.log(response.data)
          } catch (error) {
            dispatch(fetchFacilityItemsFailure(error.message))
          }
        }
    
        fetchItems()
      }, [dispatch])


    return (
        <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp " data-wow-delay="0.1s facilitiybutton">
                <h6 class="section-title bg-white text-center text-primary px-3">Our</h6>
                <h1 class="mb-5">Facilities</h1>
            </div>

          
            <div  class="row g-4 justify-content-center">
            {facilitycontent && facilitycontent.map((item, index) => (
                
                <div key={index} class="col-lg-12 col-md-6 wow fadeInUp titleanimation" data-wow-delay="0.1s" >
                    <div class="course-item bg-light">
                        <div className="row"> 
                        <div class="col-lg-4 position-relative overflow-hidden">
                            <img class="img-fluid" src="img/course-1.jpg" alt=""/>
                            
                        </div>

                        <div class="col-lg-8 text-left p-4 pb-0">
                           
                            
                           <h5 class="mb-4">{item.title}</h5>
                           <p> {item.content}</p>
                       </div>
                        </div>
                      
                     
                      
                    </div>
                </div>
           
           ))}
            </div>
          
        </div>
        
    </div>
    )
}
export default Facilities