import {legacy_createStore as createStore, applyMiddleware, combineReducers} from 'redux'
//import thunkMiddleware from 'redux-thunk'; // Use redux-thunk for asynchronous actions
import slidderreducer  from './reducers/slidderreducer'
import overviewreducer from './reducers/overviewreducer'
import alumnireducer from './reducers/alumnireducer'
import aboutusreducer from './reducers/aboutusreducer'
import achivementreducer from './reducers/achivmentreducer'
import facilityreducer from './reducers/facilityreducer'
import galleryreducer from './reducers/galleryreducer'
import videoreducer from './reducers/videoreducer'
 

const rootReducer = combineReducers({
   slidderreducer : slidderreducer,
   overviewreducer : overviewreducer,
   alumnireducer : alumnireducer,
   aboutusreducer : aboutusreducer,
   achivementreducer : achivementreducer,
   facilityreducer: facilityreducer,
   galleryreducer :galleryreducer,
   videoreducer :videoreducer
    
    
  })


const store = createStore(rootReducer);

export default store;