import {
  FITCH_FACILITY_ITEM_REQUEST,
  FITCH_FACILITY_ITEM_SUCCESS,
  FITCH_FACILITY_ITEM_FAILURE,
  ADD_FACILITY_CONTENT,
  UPDATE_FACILITY_CONTENT,
  DELETE_FACILITY_CONTENT,
} from '../constants/constants'

const initialState = {
  facilitycontent: [],
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FITCH_FACILITY_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FITCH_FACILITY_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        facilitycontent: action.payload,
      }
    case FITCH_FACILITY_ITEM_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      }
    case ADD_FACILITY_CONTENT:
      return {
        ...state,
        loading: true,
        facilitycontent: [...state.facilitycontent, action.payload],
      }
    case UPDATE_FACILITY_CONTENT:
      const contents = state.facilitycontent.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              content: action.payload.content,
              images: action.payload.images,
              title: action.payload.title,
            }
          : item,
      )

      return {
        ...state,
        loading: true,
        facilitycontent: contents,
      }

    case DELETE_FACILITY_CONTENT:
      const removeContents = state.facilitycontent.filter((a) => a.id != action.payload.id)

      return {
        ...state,
        loading: true,
        facilitycontent: removeContents,
      }

    default:
      return state
  }
}

export default reducer
