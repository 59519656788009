import {
    FITCH_ACHIVEMENT_ITEM_REQUEST,
    FITCH_ACHIVEMENT_ITEM_SUCCESS,
    FITCH_ACHIVEMENT_ITEM_FAILURE,
    ADD_ACHIVEMENT_CONTENT,
    UPDATE_ACHIVEMENT_CONTENT,
    DELETE_ACHIVEMENT_CONTENT,
  } from '../constants/constants'
  
  const initialState = {
    achivementcontent: [],
    loading: false,
    error: '',
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FITCH_ACHIVEMENT_ITEM_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case FITCH_ACHIVEMENT_ITEM_SUCCESS:
        return {
          ...state,
          loading: true,
          achivementcontent: action.payload,
        }
      case FITCH_ACHIVEMENT_ITEM_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload,
        }
      case ADD_ACHIVEMENT_CONTENT:
        return {
          ...state,
          loading: true,
          achivementcontent: [...state.achivementcontent, action.payload],
        }
      case UPDATE_ACHIVEMENT_CONTENT:
        const contents = state.achivementcontent.map((item) =>
          item.id === action.payload.id ? { ...item, content: action.payload.content, title: action.payload.title,image: action.payload.fileName} : item,
        )
  
        return {
          ...state,
          loading: true,
          achivementcontent: contents,
        }
  
      case DELETE_ACHIVEMENT_CONTENT:
        const removeContents = state.achivementcontent.filter((a) => a.id != action.payload.id)
  
        return {
          ...state,
          loading: true,
          achivementcontent: removeContents,
        }
  
      default:
        return state
    }
  }
  
  export default reducer
  