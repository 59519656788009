import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
 import { BrowserRouter, Form } from 'react-router-dom';
import { Carousel } from 'bootstrap';
import jQuery from 'jquery';
import * as bootstrap from 'bootstrap'
import './assets/css/stylethem.css'
 
 import './assets/css/animate/animate.min.css'
  import './assets/css/owlcarousel/assets/owl.carousel.min.css'
  import './assets/css/bootstrap/bootstrap.min.css'
import DefaultLayout from './components/DefaultLayout/DefaultLayout';

  
 createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <Provider store={store}>
     <BrowserRouter>
        <DefaultLayout/>
     </BrowserRouter>
  </Provider>
</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
