import React from "react"

 const Footer = () => {
 

  return (
    <div> 
           <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Quick Link</h4>
                    <a className="btn btn-link" href="/Aboutus">About Us</a>
                    <a className="btn btn-link" href="/Facilities">Facilities</a>
                    <a className="btn btn-link" href="">Events & Gallery</a>
                    <a className="btn btn-link" href="/Fees">Fees</a> 
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Contact</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>8, Susie Sorabji Rd, Agarkar</p>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Nagar Pune, Maharashtra 411001,</p>
                      <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>India Tel.No: 020 - 2612 6796</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>sthelenas.school@gmail.com</p>
                  
                </div>
               
                <div className="col-lg-3 col-md-6">
                <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div> 
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="#">ST Helenas School</a>, All Right Reserved.

                      
                         
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu"> 
                            <a href="">Cookies</a>
                            <a href="">Help</a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
         </div>
  )
}

export default Footer